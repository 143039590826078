import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import truck_highway from "./Resources/truckhighway.png";


class HomepageSectionThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  


  render() {
    return (
      <div className="home-container" style={{marginTop:30+'px'}}>

      
<div className="sectionthree row text-center">
  <div className="welcomeimg col-md-5">
    <img src={truck_highway} alt="" />
  </div>


  <div className="sectionthree_content col-md-5 text-center ">
      <h2>OUR CORE SERVICES</h2>
      

      <h5> Freight Transportation </h5>

      <p className='text-dark'>
      Our core competency lies in transporting freight of all sizes and types.
      Whether you have small packages, bulk shipments, or specialized cargo,
      we have the expertise and resources to ensure safe and timely delivery to any destination.
      </p>


      <h5>  Last-Mile Delivery </h5>

      <p className='text-dark'>
      We understand the critical importance of the last mile in the supply chain.
      Our dedicated last-mile delivery services ensure that your goods
      reach their final destination with precision and reliability,
      enhancing the overall customer experience.   
      </p>


      <h5> Customized Solutions </h5>

      <p className='text-dark'>
      Recognizing that each business has unique requirements, we offer tailored logistics
      solutions. Whether you need specialized cargo handling, or specific delivery schedules,
      our team works closely with you to design a solution that fits your needs perfectly.
      </p>


        <div className="hero-buttons">
        <Link to="/Our_services" className="btn btn-info">
            <button type='button' className="btn btn-info">Explore Services! 
            <i class="fa-solid fa-arrow-right"></i>
            </button>
            </Link>
          </div>

        


        </div>

      </div>



          <div class="banner">
            <div class="left-part">
              <div class="banner_text">
                <h2>ARE YOU LOOKING FOR</h2>
                <h2>RELIABLE TRANSPORT SERVICES?</h2>
              </div>
            </div>
            <div class="right-part">
              <div class="banner_text">
                <h2>GIVE US A CALL</h2>
                <h2>+254 752 554 470 </h2>
                <h2>+254 715 554 470</h2>
              </div>
            </div>
          </div>


      </div>
    );
  }
}

export default HomepageSectionThree;
