import React, { Component } from 'react';
import Region from './Regionals'; // Import the Region component

class MalindiRegion extends Component {
  render() {
    // Define the region-specific data
    const nairobiRegionData = {
      regionName: 'Malindi Region',
      bishopImage: 'https://img.freepik.com/free-vector/hand-drawn-flat-design-pastor-illustration_23-2149336956.jpg',
      bishopDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ducimus.',
      bishopName: 'REV MERCY YAA',
      churches: [
        { name: 'MALINDI', link: '/Manyatta' },
        { name: 'KILIFI', link: '/Kiritiri' },      
       
      ],
      mission: 'At Nairobi Kingdom Aflame Region, our mission is to fervently serve as a beacon of hope, love, and faith within our diverse and vibrant community. Rooted in our unwavering commitment to God\'s word and guided by the teachings of Jesus Christ, we strive to make a profound and lasting impact on the lives of those we touch.',
    };

    return (
      <Region {...nairobiRegionData} />
    );
  }
}

export default MalindiRegion;
