import React, { useState } from 'react';
import './App.css';
import HomepageSectionSix from './sectionsix';


function GetQoutePage() {
  const [formData, setFormData] = useState({
    loadSize: '',
    cargoType: '',
    loadingPoint: '',
    offloadingPoint: '',
    mileage: '',
    border: '',
    agreementAndPayment: '',
    contactInformation: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can add code to send the form data via email
    // console.log('Form Data:', formData);

    const formSpreeURL = 'https://formspree.io/f/xayrnnad'

    fetch(formSpreeURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Form Data Submitted:', data);

        alert ('success, We are getting back to you in minutes!');

        setTimeout(() => {
          window.location.reload();
        }, 2000);

      })
      .catch((error) => {
        console.error('Error Submitting Form Data:', error);
        alert ('Not Submitted', error);
      });


  };

  return (
    <div className='GetQoutePagecontainer'>

    <div className="GetQoutePageApp">
      
      <form onSubmit={handleSubmit} className="quote-form">

      <h3 className="card-title"
      style={{marginBottom: 20 +'px'}}
      >
        Complete the form below
        </h3>

        <label>
          Load Size (in tonnes):
          <input type="text" name="loadSize" value={formData.loadSize} onChange={handleChange} />
        </label>

        <label>
          Type of Cargo:
          <input type="text" name="cargoType" value={formData.cargoType} onChange={handleChange} />
        </label>

        <label>
          Loading Point:
          <input type="text" name="loadingPoint" value={formData.loadingPoint} onChange={handleChange} />
        </label>

        <label>
          Offloading Point:
          <input type="text" name="offloadingPoint" value={formData.offloadingPoint} onChange={handleChange} />
        </label>

        <label>
          Mileage:
          <input type="text" name="mileage" value={formData.mileage} onChange={handleChange} />
        </label>

        <label>
          Border to be Used:
          <input type="text" name="border" value={formData.border} onChange={handleChange} />
        </label>

        <label>
          Agreement and Terms of Payment:
          <select name="agreementAndPayment" value={formData.agreementAndPayment} onChange={handleChange}>
            <option value="COD">COD</option>
            <option value="Within a week">Within a week</option>
            <option value="Within a month">Within a month</option>
          </select>
        </label>

        <label>
          Contact Information:
          <input type="text" name="contactInformation" value={formData.contactInformation} onChange={handleChange} />
        </label>

        <br></br>

        <button className='GetQoutePageSubmitbutton' type="submit">Submit</button>
      </form>

    </div>
    <HomepageSectionSix />
    </div>
  );
}

export default GetQoutePage;
