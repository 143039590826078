import React, { useState } from 'react';
import './App.css';
import HomepageSectionSix from './sectionsix';


function ContactPage() {
  const [formData, setFormData] = useState({
    Name: '',
    Message: '',
    contactInformation: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can add code to send the form data via email
    // console.log('Form Data:', formData);

    const formSpreeURL = 'https://formspree.io/f/xjvngkgl'

    fetch(formSpreeURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Form Data Submitted:', data);

        alert ('success, We are getting back to you in minutes!');

        setTimeout(() => {
          window.location.reload();
        }, 2000);

      })
      .catch((error) => {
        console.error('Error Submitting Form Data:', error);
        alert ('Not Submitted', error);
      });


  };

  return (
    <div className='GetQoutePagecontainer'>

    <div className="GetQoutePageApp">
      
      <form onSubmit={handleSubmit} className="quote-form">

      <h3 className="card-title"
      style={{marginBottom: 20 +'px'}}
      >
        Leave a comment, insight or message below.
        </h3>

        <label>
          Name:
          <input type="text" name="Name" value={formData.Name} onChange={handleChange} />
        </label>

        <label>
          Message:
          <input type="text" name="Message" value={formData.Message} onChange={handleChange} />
        </label>


        <label>
          Contact Information:
          <input type="text" name="contactInformation" value={formData.contactInformation} onChange={handleChange} />
        </label>

        <br></br>

        <button className='GetQoutePageSubmitbutton' type="submit">Submit</button>
      </form>

    </div>

    <HomepageSectionSix />
    </div>
  );
}

export default ContactPage;
