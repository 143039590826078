import React, { Component } from 'react';


class HomepageSectionFive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  

  render() {
    return (
      <div className="home-container">

<div className='sectionfive'>
<div className='content_sectionfive m-4 p-2 mt-4'>
<h3 className='text-center mt-6'>Plan A Visit</h3>
</div>

<div className='maps_sectionfive'>

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.6787784786106!2d36.92395909254665!3d-1.3735193009624165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1785bd235ef9204b%3A0xf5944e4a704b1486!2sOrion%20Hauliers%20Ltd!5e1!3m2!1sen!2ske!4v1703830385354!5m2!1sen!2ske" 

style={{border: 0, height: 500 + "px" }} title={"location"}
allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

</div>

</div>
      </div>
    );
  }
}

export default HomepageSectionFive;
