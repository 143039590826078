import React, { Component } from 'react';
import HomepageSectionSix from '../sectionsix';


class TankerService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  

  render() {
    return (
<div className="about_company_Container">

<div className='about_company_App'>




<div className='about_company_card'>
<div className="col-lg-12 col-md-12 mb-4">
      <h5 className="card-title">INTRODUCTION</h5>

<p>
Orion Hauliers Ltd, has been a pioneering force in East African logistics since our incorporation
in 1995. For over 28 years, we have evolved into a symbol of reliability, efficiency,
and innovation, committed to transforming logistical challenges into opportunities.
  We have grown from a vision into a formidable force, consistently delivering excellence
  in every mile we travel.

  

</p>

<p>
Fueled by a passion for reliability and a commitment to excellence, 
our journey began, navigating challenges and transforming each obstacle into an opportunity for growth.
From the outset, our strategy has been dynamic and forward-thinking, 
characterized by adaptability and a proactive approach to industry changes. 
Rather than adhering to the traditional narrative of rapid expansion, 
we embraced a deliberate and calculated pace. This approach allowed us to focus on quality,
 innovation, and building enduring relationships with our clients.

           
</p>

<p>
Orion Hauliers Ltd has consistently been at the forefront of pioneering logistics solutions. Our commitment to innovation and adaptability has allowed us to stay ahead of industry trends, offering our clients cutting-edge services tailored to their evolving needs.
One of the hallmarks of our journey has been a relentless pursuit of excellence in every aspect of our operations. From investing in road-worthy trucks, state-of-the-art technology to fostering a culture of continuous improvement, we have positioned ourselves as more than just a logistics provider — we are architects of seamless supply chain solutions.

</p>            
      </div>
      </div>






      <div className='about_company_card'>
<div className="col-lg-12 col-md-12 mb-4">
          <h5 className="card-title">MISSION AND VISION</h5>
          <h9 className="card-title" >Vision:</h9>
            <p>
To be the ultimate regional logistics and supply chain management solutions provider, offering unparalleled customer satisfaction through innovation, reliability and excellence.
</p>
<h9 className="card-title">Mission:</h9>
<p>
To create significant value to our customers and lasting partnerships; by promoting a flexible and customer-oriented corporate culture that encourages continuous improvement in our systems and services; through our people, processes, and technology.
</p>
            </div>
          </div>
        





      <div className='about_company_card'>
<div className="col-lg-12 col-md-12 mb-4">
          <h5 className="card-title">The Road Ahead:</h5>
            <p>
            As we reflect on our remarkable journey, we recognize that the road ahead holds even greater promise. With a foundation built on excellence, innovation, and client satisfaction, Orion Hauliers Ltd is poised to continue shaping the future of logistics in East Africa.
</p>

<p>
Join us on this exciting expedition. Whether you're a long-time partner or considering a collaboration, let's embark on a journey of growth, reliability, and unparalleled logistics solutions.
</p>


            </div>
          </div>
        




      </div>
      <HomepageSectionSix />
      </div>
      
    );
  }
}

export default TankerService;
