import React, { Component } from 'react';
import FooterPage from '../AllFooter';


class PodcastPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete_data : [],
      isLoading: true
    };
  }


  singleObject = () => {
    let data = this.state.complete_data.length > 0 ? JSON.parse(this.state.complete_data) : 0;

    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }else{
            return   data.map(singles => {
            return(



<div key={singles.id} className="col-lg-12 col-md-12 mb-4">
        <div className="card">
          <div className="card-body">
          <div className="card-content">
          <div className="embed-responsive embed-responsive-16by9">
          <audio controls>
  <source src={singles.recording} type="audio/mpeg" />
  Your browser does not support the audio element.
</audio>

          </div>
          
            <h5 className="card-title">{singles.title}</h5>
            <p className="card-text">{singles.description}</p>
            <p className="card-text"> <span> {singles.author} </span> <span>{singles.date_posted}</span></p>
            <button className="btn btn-primary">Like</button>
            </div>
          </div>
        </div>
      </div>



            )
                })
    }
       };


    componentDidMount(){
    fetch("http://127.0.0.1:8000/api/Podcast/")
    .then(response => response.json())
    .then(data =>  {
        let mydata = JSON.stringify(data);
        this.setState({complete_data : mydata, isLoading: false })
    })
    .catch(error => console.error(error))
  }



  

  render() {
    return (
      <div>
<div className="BlogPage_Container">

<div className="container">
<div className="row align-items-stretch">
{this.singleObject()} 
</div>
</div>


      </div>
      <FooterPage />
      </div>
      
    );
  }
}

export default PodcastPage;
