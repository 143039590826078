import { Link } from 'react-router-dom';
import './App.css';
import logo from "./Resources/orion logo round.png";
// import logoname from "./Resources/name.png";

function Navigation() {
  return (
    
       <nav className="navbar navbar-expand-lg navbar-dark  text-#dab87f sticky-top">

        <div className="logo-container">

        {/* <Link to="/" className="navbar-logo navbar-brand"> */}

          <img src={logo} alt="Orion Hauliers Ltd" className="navbar-logo"  />

          {/* <img src={logoname} alt="Orion Hauliers Ltd" className="navbar-logoname"/> */}

        {/* </Link> */}

        </div>

        <button className="navbar-toggler" type="button" 
        data-toggle="collapse" data-target="#navbarSupportedContent" 
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

    <span className="navbar-toggler-icon"></span>
  </button>

      <div className="collapse navbar-collapse" id='navbarSupportedContent'>
    

        <ul className="navbar-nav mr-auto">
          
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
           

          <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Services
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link to="/Our_services" className="dropdown-item">All Services</Link></li>
                <li><Link to="/Services_Containerised_cargo" className="dropdown-item">Containerized Cargo Transport</Link></li>
                <li><Link to="/Services_Full_Truck_Load" className="dropdown-item">Tanker Cargo Transport</Link></li>
                <li><Link to="/Services_Intermodal" className="dropdown-item">Full TruckLoad (FTL) </Link></li>
                <li><Link to="/Service_Tanker" className="dropdown-item">Intermodal Transportation</Link></li>
         
        </div>
      </li>



          <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          About us
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link to="/About_Orion" className="dropdown-item">The Company</Link></li>
                <li ><Link to="/About_Team" className="dropdown-item">Our team</Link></li>
             
              </div>
          </li>

          <li className="nav-item">
            <Link to="/Gallery" className="nav-link">
              Gallery
            </Link>
          </li>


          <li className="nav-item">
            <Link to="/Contact_Us" className="nav-link">
              Contact Us
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/Get_a_qoute" className="nav-link">
              Get a Quote
            </Link>
          </li>



        </ul>
      </div>
      

    </nav>

    
  );
}

export default Navigation;
