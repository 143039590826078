import React, { Component } from 'react';
import HomepageSectionTwo from './sectiontwo';
import HomepageSectionThree from './sectionthree';
import HomepageSectionFour from './sectionfour';
import HomepageSectionFive from './sectionfive';
import HomepageSectionSix from './sectionsix';
import { Link } from 'react-router-dom';



class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  

  render() {
    return (
      <div className="">

{/* section one */}
        <div className='hero'>
        
         
        <div className="content_container_sectionone">

          <div className='hero-text mt-5 mt-5'>
            <h1>DISCOVER A LEADING PLAYER IN THE LOGISTICS INDUSTRY</h1>

          <p>
             Our commitment to reliability, transparency,
             and efficiency sets us apart in the competitive world of logistics.
             <br/>
             When you choose Orion Hauliers Ltd, you choose a partner dedicated to your success;
              every journey is a partnership, and every delivery is a promise kept.     
             </p>
         
          
          <div className="hero-buttons">
           <Link to="/Our_services" className="btn btn-info">
            <button type='button' className="btn btn-info">View Services 
            <i class="fa-solid fa-arrow-right"></i>
            </button>
            </Link>
          </div>

        </div>

        </div>


        </div>

        <HomepageSectionTwo />
        <HomepageSectionThree />
        {/* <HomepageSectionFour /> */}
        <HomepageSectionFive />
        <HomepageSectionSix />

      </div>
    );
  }
}

export default Homepage;
