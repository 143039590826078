import React, { Component } from 'react';


class HomepageSectionSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  

  render() {
    return (
      <div className="home-container footer ">

<div className='sectionsix row'>
<div className="col-md-4 socials text-center">
  <h4>Our Socials</h4>
        <p className='text-center text-justify'><a href='\Facebook_events' >Facebook</a><br></br>
        <a href='\Twitter_events' >Twitter</a><br></br>
        <a href='\Instagram_events' >Instagram</a>
        </p>
  </div>
  <div className="col-md-4">
      <h4>About Orion Hauliers Ltd</h4>
      <p>
        
        The company's roots can be traced back to a small garage in Cabanas (Nairobi), 
      where it was set up in the early 2000's. It is currently located along Mombasa road, off Kiungani
      road, Syokimau.The company's reputation for punctuality and professionalism attracted a growing
      clientele, leading to increased demand for its services. Briefly, the company is exploring
      sustainable practices and further technological enhancements to continue its trajectory
       of growth and success
        
        </p>
  </div>
  
       <div className="col-md-4 connect">
        <h4>Get a Quote</h4>
        
        <p className='text-center text-justify'><a href='\Facebook_events' >Containerized Cargo</a><br></br>
        <a href='\Twitter_events' >Loose Cargo</a><br></br>
        <a href='\Instagram_events' >Oil and Gas</a><br/>

        <h4 style={{marginTop: 20 + "px"}}>Contact us</h4>
              <span>+254 752 554 470 / </span> 
              <span>+254 715 554 470</span><br />
              <span>sales@orionhauliers.com</span>
              
        {/* <a href='\Instagram_events' >Give</a> */}

        </p>
       </div>
       
</div>
      </div>
    );
  }
}

export default HomepageSectionSix;
