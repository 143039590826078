import React, { Component } from 'react';
import FooterPage from '../AllFooter';
import logo from "../Resources/logo.jpg";
import { Link } from 'react-router-dom';
// import HomepageSectionSix from './sectionsix';



class EmbuRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }


leaderCard = (image_src,leader_name,contact,ministering,story) => {
  return(
  <div className="row align-items-stretch">

<div className="col-lg-3 col-md-6 mb-4">
        <div className="card">
          <div className="card-body">
          <div>
          <img 
          src={image_src} alt="Kingdom Flame" 
          className="card-img-top" 
          style={{ width: '100%', maxHeight: '300px' }}
           />
          </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
          <div className="card-content">
            <h5 className="card-title">{leader_name}</h5>
            <p className="card-text">Contact Details: {contact} </p>
            <p className="card-text">Kingdom Aflame Ministry {ministering} </p>
            </div>
          </div>
        </div>

      </div>


      <div className="col-lg-9 col-md-6 mb-4">
        <div className="card">
          <div className="card-body">
          <div className="card-content">
            <p className="card-text">
              {story}
            </p>
            </div>
          </div>
        </div>
      </div>

      <hr/>
</div>
  );

}

  

  render() {
    return (
      <div>
 <div className="LeadersPage_Container">

<div className="container-fluid">

     
{/* calling the leaders card and providing the appropriate parameters */}
<h3 className='text-center mt-5'>EMBU REGION </h3>
<div className="row m-3 p-4">
  <div className="col-md-5">
    <div className=" p-2 m-1 ">
      <img className='bishopimg text-justify' src="https://img.freepik.com/free-vector/hand-drawn-flat-design-pastor-illustration_23-2149336956.jpg" alt="" />
      <h4>Bishop Kendo</h4>
      <p className='text-dark'>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ducimus. 
      </p>
    </div>
  </div>
  <div className="col-md-7 ourchurches">
    <h4>Our Churches</h4>
    <div className='churches row'>
      <div className="col-md-3">
      <Link to="/Church" className='link'>
      Manyatta
      </Link>
      </div>
    <div className="col-md-3">
    <li>Kiritiri</li>
    </div>
    <div className="col-md-3">
    <li>Runyenjes Central</li>
    </div>
    <div className="col-md-3">
    <li>Gaturi</li>

    </div>
  
    </div>
    <div className="mission mt-4">
      <h4>Our Mission</h4>
      <p>At Embu Kingdom Aflame Region, our mission is to fervently serve as a beacon of hope, love, and faith within our diverse and vibrant community. Rooted in our unwavering commitment to God's word and guided by the teachings of Jesus Christ, we strive to make a profound and lasting impact on the lives of those we touch.</p>
    </div>
    <div className="">
      <button className='give'> Explore  <i class="fa-brands fa-wpexplorer"></i></button>
    </div>
  </div>
</div>


</div>
      </div>
      <div className="footer">
      <div className="home-container footer ">

<div className='sectionsix row'>
<div className="col-md-4 socials text-center">
  <h4>Our Socials</h4>
        <p className='text-center text-justify'><a href='\Facebook_events' >Facebook</a><br></br>
        <a href='\Twitter_events' >Twitter</a><br></br>
        <a href='\Instagram_events' >Instagram</a>
        </p>
  </div>
  <div className="col-md-4">
      <h4>About Kingdom Aflame</h4>
      <p>"Kingdom Aflame Church in Kapsoya, Kenya, is a vibrant and inclusive spiritual community committed to igniting faith, hope, and love. Through worship, outreach, and teaching, the church aims to empower individuals and bring positive change to Kapsoya and beyond."</p>
  </div>
  
       <div className="col-md-4 connect">
        <h4>Connect</h4>
        
        <p className='text-center text-justify'><a href='\Facebook_events' >Weekends</a><br></br>
        <a href='\Twitter_events' >Get Involved</a><br></br>
        <a href='\Instagram_events' >Contact Us</a><br/>
        <a href='\Instagram_events' >Give</a>

        </p>
       </div>
       
</div>
      </div>
      </div>

      </div>
    );
  }
}

export default EmbuRegion;

