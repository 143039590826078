import React, { Component } from 'react';
import FooterPage from '../AllFooter';


class PhotoGalleryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete_data : [],
      isLoading: true
    };
  }


  singleObject = () => {
    let data = this.state.complete_data.length > 0 ? JSON.parse(this.state.complete_data) : 0;

    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }else{
            return   data.map(singles => {
            return(

<div>
      <div key={singles.id} className="row align-items-stretch">

      <div className="col-lg-3 col-md-6 mb-4">
            <div className="card">
              <div className="card-body">
              <div>
              <img 
          src={singles.video} alt="Kingdom Aflame" 
          className="card-img-top" 
          style={{ width: '100%', maxHeight: '300px' }}
           />
                </div>
              </div>
            </div>
          </div>



      <div key={singles.id} className="col-lg-9 col-md-6 mb-4">
      <div className="card" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{singles.title}</h5>
            <p className="card-text">{singles.description}</p>
            <p>{singles.date_posted}</p>
            <button className="btn btn-primary">Like</button>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>


            )
                })
    }
       };


    componentDidMount(){
    fetch("http://127.0.0.1:8000/api/Photogallery/")
    .then(response => response.json())
    .then(data =>  {
        let mydata = JSON.stringify(data);
        this.setState({complete_data : mydata, isLoading: false })
    })
    .catch(error => console.error(error))
  }



  

  render() {
    return (
      <div>
<div className="BlogPage_Container">

<div className="container">
<div className="row align-items-stretch">
{this.singleObject()} 
</div>
</div>


      </div>
      <FooterPage />
      </div>
      
    );
  }}

export default PhotoGalleryPage;
