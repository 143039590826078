import React, { Component } from 'react';
import HomepageSectionSix from '../sectionsix';
// import tanker_cargo from "../Resources/tanker cargo.jpeg";
import tanker_cargo_two from "../Resources/tanker_cargo_two.jpeg";
import containerised_cargo from "../Resources/containerised.jpeg";
import intermodal_cargo from "../Resources/intermodal.jpeg";
import full_truck from "../Resources/trucks at yard.jpeg";





class AllServicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }




  render() {
    return (
      <div className="AllServicesPage_Container">
       <div className="AllServicesPage_App">
       

       <h5 className="card-title m-4 text-center">OUR SERVICES</h5>




<div className='allServices_card'>
      <div className="row align-items-stretch">


      <div className="col-md-6 mb-4">
      <div className="allServices_card_wordings" style={{color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">CONTAINERISED CARGO TRANSPORTATION</h5>
          <p className="card-text">
          Our containerized cargo transport service is tailored for the seamless movement of goods using standardized containers. 
          </p>
          <p className="card-text">
          These containers, typically 20 or 40 feet in length, provide a secure and efficient method for transporting various types of cargo.
          </p>
          </div>
        </div>
      </div>
    </div>

      <div className=" col-md-6 mb-4">
            <div className="">
              <div>
              <img 
          src={containerised_cargo} alt="ORION HAULIERS" 
          className="women-img" 
          
           />
              </div>
            </div>
          </div>


    </div>
    <hr />
    </div>





    <div className='allServices_card'>
      <div className="row align-items-stretch">


      <div className="col-md-6 mb-4">
      <div className="allServices_card_wordings" style={{color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">TANKER CARGO TRANSPORTATION</h5>
          <p className="card-text">
          Specializing in the transportation of liquid food grade, our tanker cargo transport service is designed for the safe and efficient movement of bulk liquids.
          </p>
          With a focus on safety, our tanker transport adheres to rigorous standards and regulations. This service is crucial for industries where the precise and secure transportation of liquid cargo is paramount.
          <p className="card-text">
          </p>
          </div>
        </div>
      </div>
    </div>


      <div className=" col-md-6 mb-4">
            <div className="">
              <div>
              <img 
          src={tanker_cargo_two} alt="ORION HAULIERS" 
          className="women-img" 
          
           />
              </div>
            </div>
          </div>


    </div>
    <hr />
    </div>




    <div className='allServices_card'>
      <div className="row align-items-stretch">


      <div className="col-md-6 mb-4">
      <div className="allServices_card_wordings" style={{color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">FULL-TRUCK-LOAD SERVICES</h5>
          <p className="card-text">
          Our FTL services offer exclusive truck utilization for single shipments, providing clients with dedicated and direct transportation. This is an optimal solution for larger shipments that require the entire capacity of a truck.
          </p>
          <p className="card-text">
          FTL services ensure the swift and direct delivery of your cargo, minimizing handling and transit times. It's a cost-effective choice for clients with substantial shipping needs and a preference for personalized service.
          </p>
          </div>
        </div>
      </div>
    </div>


    <div className=" col-md-6 mb-4">
            <div className="">
              <div>
              <img 
          src={full_truck} alt="ORION HAULIERS" 
          className="women-img" 
          
           />
              </div>
            </div>
          </div>


    </div>
    <hr />
    </div>





    <div className='allServices_card'>
      <div className="row align-items-stretch">


      <div className="col-md-6 mb-4">
      <div className="allServices_card_wordings" style={{color:'white'}}>
        
        <h5 className="card-title">INTERMODAL TRANSPORTATION</h5>
          <p className="card-text">
          Intermodal transportation seamlessly transfers cargo between different modes of transport, such as trucks, trains, and ships. This service optimizes efficiency and offers a versatile solution for long-distance transportation.
          </p>
          <p className="card-text">
          By leveraging the strengths of each transportation mode, intermodal services provide a cost-effective and environmentally friendly solution. It's an ideal choice for clients seeking flexibility and sustainability in their supply chain.
          </p>
          
       
      </div>
    </div>



    <div className=" col-md-6 mb-4">
            <div className="">
              <div>
              <img 
          src={intermodal_cargo} alt="ORION HAULIERS" 
          className="women-img" 
          
           />
              </div>
            </div>
          </div>



    </div>
    <hr />
    </div>






{/* why us section */}




<div className='whyData'>


<hr></hr>

<h2 className="card-title m-4 text-center">WHY US ?</h2>

<hr></hr>


<div className='whyData_card'>
<div className="whyData_card_wordings" >


            <h5> 1. Standby Mechanics:</h5>

<p className='text-dark'>
We prioritize the reliability and performance of our vehicles by having skilled mechanics ready to address any mechanical issues promptly. This commitment minimizes downtime and ensures the continuous flow of your cargo.
</p>

<h5>2. 24/7 Cargo Monitoring and Tracking:</h5>

<p className='text-dark'>
Utilizing advanced tracking technology, we empower our clients with the ability to monitor their cargo at any time. This transparency enhances security, efficiency, and allows for proactive decision-making.
</p>

<h5>3. Competent Drivers:</h5>

<p className='text-dark'> Our commitment to safety and professionalism is reflected in the competency of our drivers. Rigorous training programs and continuous skill development contribute to the reliability and expertise of our driving team.
</p>

<h5>4. KRA (Regulatory Body) Compliant:</h5>

<p className='text-dark'>
Our adherence to KRA regulations demonstrates our commitment to ethical and legal standards, providing our clients with confidence in the integrity of our operations.
</p>

<h5>5. 3rd Party Tagging (ECTs):</h5>

<p className='text-dark'>
We facilitate 3rd party Electronic Cargo Tracking (ECT) for enhanced cargo security and compliance with industry standards.
</p>

<h5>6. Roadworthy and Documented Trucks:</h5>

<p className='text-dark'>
We prioritize the compliance and safety of our fleet by regularly conducting maintenance checks, ensuring all trucks meet roadworthiness standards. Comprehensive documentation accompanies each vehicle, streamlining regulatory processes.
</p>

<h5>7. Compliance with Third-Party Checkpoints provider:</h5>

<p className='text-dark'>
We collaborate with third-party entities to set up checkpoints, ensuring drivers are sober, and cargo is securely transported.
</p>

<h5>8. Experienced Logistics Professionals:</h5>

<p className='text-dark'>
 Drawing on years of experience, our dedicated team possesses the expertise needed to navigate the complexities of logistics, providing strategic solutions and ensuring the seamless movement of your cargo.
</p>

<h5>9. Environmental Responsibility:</h5>

<p className='text-dark'>
 We embrace environmentally responsible practices, optimizing routes and adopting eco-friendly technologies to reduce our carbon footprint.
</p>

<p className='text-dark'>
Choose us for a reliable, secure, and efficient logistics partner dedicated to meeting your unique transportation needs with professionalism and integrity.
</p>



</div>
</div>

        </div>

         



             </div>
             <HomepageSectionSix />
       







            </div>





    );
  }
}

export default AllServicesPage;
