import React, { Component } from 'react';
import FooterPage from '../AllFooter';


class SermonsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete_data : [],
      onestudent : [],
      example : [],
      isLoading: true
    };
  }


  singleObject = () => {
    let data = this.state.complete_data.length > 0 ? JSON.parse(this.state.complete_data) : 0;

    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }else{
            return   data.map(singles => {
            return(
          

              <div>
      <div className="row align-items-stretch">

      <div className="col-lg-3 col-md-6 mb-4">
            <div className="card">
              <div className="card-body">
              <div className="embed-responsive embed-responsive-16by9">
            <video className="embed-responsive-item" muted controls loop style={{ width: '100%', height: '100%' }}>
            <source src={singles.video} type="video/mp4" />
              </video>
          </div>
              </div>
            </div>
          </div>



      <div className="col-lg-9 col-md-6 mb-4">
      <div className="card" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{singles.title}</h5>
          <p className="card-text">
              {singles.description}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>


            )
                })
    }
       };


    componentDidMount(){
    fetch("http://127.0.0.1:8000/api/Sermons_data/")
    .then(response => response.json())
    .then(data =>  {
        let mydata = JSON.stringify(data);
        this.setState({complete_data : mydata, isLoading: false })
    })
    .catch(error => console.error(error))
  }

  

  render() {
    return (
      <div>
<div id="SermonsPage_Container" className='jumbotron'>
<p>WATCH SOME OF OUR PAST SERVICES...</p>


<div className="container">
<div className="row align-items-stretch">
{this.singleObject()}
</div>
</div>




      </div>
      <div>      
      <FooterPage />
      </div>
      </div>
      
    );
  }
}

export default SermonsPage;
