import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Region extends Component {
  render() {
    const { regionName, bishopImage, bishopName, bishopDescription, churches, mission } = this.props;

    return (
      <div>
        <div className="LeadersPage_Container">
          <div className="container-fluid">
            <h3 className='text-center mt-5'>{regionName}</h3>
            <div className="row m-3 p-4">
              <div className="col-md-5">
                <div className="p-2 m-1">
                  <img className='bishopimg text-justify' src={bishopImage} alt="" />
                  <h4>{bishopName}</h4>
                  <p className='text-dark'>{bishopDescription}</p>
                </div>
              </div>
              <div className="col-md-7 ourchurches">
                <h4>Our Churches</h4>
                <div className='churches row'>
                  {churches.map((church, index) => (
                    <div className="col-md-3" key={index}>
                      <Link to={`/church/${church.name}`} className='link'>
                        {church.name}
                      </Link>
                    </div>
                  ))}
                </div>
                <div className="mission mt-4">
                  <h4>Our Mission</h4>
                  <p>{mission}</p>
                </div>
                <div>
                  <button className='give'>Explore <i className="fa-brands fa-wpexplorer"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Region;
