import React, { Component } from 'react';
import FooterPage from './AllFooter';


class MultiplyFollowersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  

  campaignCard = (quoteTitle,quoteOne,quoteTwo,quoteThree) => {
    return (

      <div className="col-lg-12 col-md-12 mb-4">
      <div className="card" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{quoteTitle}</h5>
          <p className="card-text">
              {quoteOne}
          </p>
          <p className="card-text">
              {quoteTwo}
          </p>
          <p className="card-text">
              {quoteThree}
          </p>
          </div>
        </div>
      </div>
    </div>

    );
  }

  render() {
    return (
      <div>
<div className="MultiplyFollowersPage_Container">

<div className='container'>


<div className="col-lg-12 col-md-12 mb-4">
        <div className="card">
          <div className="card-body">
          <div className="card-content">
          <h5 className="card-title"><b>
          Here are a few reasons why you should consider joining us: </b></h5>
<p className="card-text"> <span><b>Inspiring Worship:</b></span> Our worship services are a time of celebration, reflection, and spiritual renewal. Through powerful messages, uplifting music, and heartfelt prayers, we seek to draw closer to God and find strength for life's challenges.
</p>
<p className="card-text"><span><b>Community Connection:</b></span>  In a world that often feels disconnected, Kingdom Aflame Ministry offers a sense of belonging and community. Our small groups, gatherings, and events provide the opportunity to build lasting friendships, share experiences, and support one another.
</p>
<p className="card-text"><span><b>Biblical Teaching:</b></span>  We believe in the life-changing wisdom of the Bible. Our teaching is grounded in God's Word, relevant to everyday life, and designed to help you grow spiritually and live out your faith with purpose.
</p>
<p className="card-text"><span><b>🙏 Prayer and Support:</b></span> No matter what you're facing, you don't have to go through it alone. Our caring community is here to support you in prayer, encouragement, and practical assistance.
</p>
<p className="card-text"><span><b>Family-Focused:</b></span>
 Kingdom Aflame Ministry is a place where families thrive. We offer programs and activities for children, youth, and adults, ensuring that everyone has the opportunity to connect, learn, and grow.
</p>
<p className="card-text"><span><b>Impactful Outreach:</b></span> 
 We are committed to making a positive impact in our local community and beyond. Through service projects, charitable initiatives, and missions, we seek to spread love, compassion, and hope to those in need.
</p>
<p className="card-text">
Join Us This Sunday: We invite you to come as you are and experience the warmth of our congregation this Sunday at [Service Time]. Our location is [Address]. We are excited to meet you and welcome you into our church family.

Feel free to reach out to us at [Contact Information] if you have any questions or would like more information about our church and its ministries.

Remember, you are loved, valued, and welcomed at Kingdom Aflame Ministry. We can't wait to share in the journey of faith together!
</p>            </div>
          </div>
        </div>
      </div>



<h2>
  </h2>




<div>


<div className="col-lg-12 col-md-12 mb-4">
        <div className="card">
          <div className="card-body">
          <div className="card-content">
          <h5 className="card-title"><b>
          Am I good enough to go to Heaven?
          </b></h5>
            <p className="card-text">
Of all the questions you will ask yourself in life, the most important is, Am I good enough to go to Heaven?
            </p>
      <p>
      The answer to that question will have eternal consequences.
      </p>
      <p>
      The way to find out is to ask yourself if you have obeyed God's standard of goodness,
the Ten Commandments.
      </p>
      <p>
      Most would say, "Well, I've broken one or two, but nothing too serious, like murder, etc."
      </p>
      <p>
      So, let's go through them and see how you do: 
      </p>


      {this.campaignCard(
        " 1. You shall have no other gods before me.",
        " Is God first in your life? Do you love God above all else? Imagine you purchased a TV for your children, but the next evening you arrived home from work and found that they didn't even bother to greet you. They were too busy watching television. By ignoring you because they preferred to watch TV, they were setting their love on the gift rather than the giver, a wrong order of affections. In the same way, if we love anything - our spouse, our children, or even our own lives-more than we love God, we are setting our affection on the gift rather than the Giver, which is a transgression of the First Commandment. In fact, the Bible says that we should love God so much that our love for our family should seem like hatred compared to the love we have for the God who gave those loved ones to us.",
        " Have you loved God with all your heart? If not, you have committed the oldest sin in the Book. You be the judge. On Judgment Day, will you be innocent or guilty of breaking that Commandment? I'm not judging you - I'm asking you to judge yourself before the Day of Judgment."
      )}



          {this.campaignCard(
          "2. You shall not make for yourself any graven image.",
          "This means that we shouldn't make a god to suit ourselves, either with our hands or with our mind. Have you made a god in your mind that you're comfortable with-where you say, My god is a loving god who would never send anyone to Hell? Perhaps your god doesn't mind a white lie or fib here and there - in fact, he doesn't have any moral dictates. In reality, that god doesn't exist; he's a figment of your imagination. If your god is not the One revealed in Holy Scripture, then you have made an image of God to suit yourself, something the Bible calls idolatry. Scripture warns that no idolater will enter the kingdom of Heaven (1 Cor. 6:9)."
          )}

          
          {this.campaignCard(
          "3. You shall not take the name of the Lord your God in vain.",
          "Have you ever taken God's name in vain-using it flippantly, like OMG, or in place of a four-letter word to express disgust? Even Hitler's name wasn't despised enough to use as a curse word. If you have taken the holy name of the God who gave you life and used it in that manner, that's called blasphemy, and the Bible says, The LORD will not hold him guiltless who takes His name in vain (Ex. 20:7)."
          )}


          {this.campaignCard(
            "4. Remember the Sabbath Day, keep it holy.",
          "I ignored this command for 22 years. Even though God gave me the gift of life, I didn't give Him one day in seven. I was guilty of breaking this Commandment."
          )}


          {this.campaignCard(
          "5. Honor your father and your mother.",
          "Have you always honored your parents implicitly, treating them in a way that's pleasing to God? This doesn't mean you honor them only if you think they are good parents. We are to respect and obey them just because they are our parents. Ask God to remind you of the sins of your youth. You may have forgotten them, but God hasn't."
          )}


          {this.campaignCard(
          "6. You shall not murder.",
          "The Bible warns that if we are angry at someone without cause we are in danger of judgment, and that whoever hates his brother is a murderer (1 John 3:15). We can violate God's Law by our attitude and intent. Have you broken this Commandment by harboring anger or hatred toward someone? If so, God sees you as guilty of murder, and no murderer has eternal life abiding in him."
          )}


          {this.campaignCard(
          "7. You shall not commit adultery.",
          "Who of us can say that we are pure of heart? Jesus warned, Whoever looks at a woman to lust for her has already committed adultery with her in his heart (Matt. 5:28). God has seen every thought you have had and every sin you have ever committed. The day will come when you will have to face His Law, and we are told that the impure, fornicators (those who have sex before marriage), and adulterers will not enter the kingdom of God (1 Cor. 6:9,10)."
          )}


          {this.campaignCard(
          "8. You shall not steal.",
          "Have you ever taken something that didn't belong to you, regardless of its value? This includes stealing an answer on a test, taking a pen from work, even keeping extra change that you know isn't rightfully yours. If you've taken anything that isn't yours, then you are a thief-and you cannot enter God's kingdom (1 Cor. 6:9,10)."
          )}


          {this.campaignCard(
          "10. You shall not covet.",
          "Have you ever coveted (jealously desired) anything that belongs to another person? That's a violation of the Tenth Commandment, and the covetous will not inherit the kingdom of God (Eph. 5:5)."
          )}


            </div>
          </div>
        </div>
      </div>







</div>


<div>






</div>

</div>

      </div>
      <FooterPage />
      </div>
      
    );
  }
}

export default MultiplyFollowersPage;
