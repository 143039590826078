import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function MinistryPage() {
  const { churchName, ministryName } = useParams();
  const [ministryData, setMinistryData] = useState(null);

  useEffect(() => {
    // Construct the API URL based on churchName and ministryName
    const apiUrl = `http://127.0.0.1:8000/api/Datastore/`;

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Set the fetched data in the state
        setMinistryData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, [churchName, ministryName]);



  console.log("ministry data", ministryData);


  // Define the women_ministry_cards function
  const women_ministry_cards = () => {
    // Create an array to store JSX elements
    const elements = [];

    ministryData.forEach((singleDataForSpecificMinistry) => {
      if (
        singleDataForSpecificMinistry.specific_church === churchName &&
        singleDataForSpecificMinistry.specific_ministry === ministryName
      ) {
        elements.push(
          <div className='women' key={elements.length}>          


            <div className='women'>
      <div className="row align-items-stretch">


      <div className=" col-md-6 mb-4">
            <div className="">
              {/* <div className="card-body"> */}
              <div>
              <img 
          src={''} alt="Kingdom Aflame" 
          className="women-img" 
          
           />
                {/* </div> */}
              </div>
            </div>
          </div>



      <div className="col-md-6 mb-4">
      <div className="ministrycard" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{singleDataForSpecificMinistry.title}</h5>
          <p className="card-text">
              {singleDataForSpecificMinistry.description}
          </p>
          <p className="card-text">
              {''}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>




          </div>
        );
      }
    });

    return elements;
  };




  if (!ministryData) {
    return <div>Loading...</div>;
  }



  return (
    <div>
      <h1>{churchName} {ministryName}</h1>
     
      {women_ministry_cards()}

    </div>
  );
}

export default MinistryPage;
