import React, { Component } from 'react';
import Region from './Regionals'; // Import the Region component

class MakueniRegion extends Component {
  render() {
    // Define the region-specific data
    const nairobiRegionData = {
      regionName: 'Makueni Region',
      bishopImage: 'https://img.freepik.com/free-vector/hand-drawn-flat-design-pastor-illustration_23-2149336956.jpg',
      bishopDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ducimus.',
      bishopName: 'REV TIMOTHY KILONZO',
      churches: [
  

        { name: 'KIBWEZI', link: '/Manyatta' },
        { name: 'MSONGALENI', link: '/Kiritiri' },
        { name: 'KATIVANI KUILU', link: '/Manyatta' },
        { name: 'MBUVONZAU', link: '/Kiritiri' },
        { name: 'NGWATE', link: '/Manyatta' },
        { name: 'KATIVANI NGWATA', link: '/Kiritiri' },
        { name: 'MACHINARY', link: '/Manyatta' },
        { name: 'KITULU', link: '/Kiritiri' },
        { name: 'KALIMANI', link: '/Manyatta' },
       
      ],
      mission: 'At Nairobi Kingdom Aflame Region, our mission is to fervently serve as a beacon of hope, love, and faith within our diverse and vibrant community. Rooted in our unwavering commitment to God\'s word and guided by the teachings of Jesus Christ, we strive to make a profound and lasting impact on the lives of those we touch.',
    };

    return (
      <Region {...nairobiRegionData} />
    );
  }
}

export default MakueniRegion;
