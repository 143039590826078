import React, { Component } from 'react';
import FooterPage from '../AllFooter';
import ophans_Community_Involvement from "../Resources/ophans_Community_Involvement.jpg"
import orphans_about from "../Resources/orphans_about.jpg"
import orphans_children_homes from "../Resources/orphans_children_homes.jpg"
import orphans_contact from "../Resources/orphans_contact.jpg"
import orphans_education from "../Resources/orphans_education.png"
import orphans_Get_involved from "../Resources/orphans_Get_involved.jpg"
import orphans_introduction from "../Resources/orphans_introduction.jpg"
import orphans_mission from "../Resources/orphans_mission.jpg"
import orphans_social from "../Resources/orphans_social.png"
import orphans_spiritual_nurturing from "../Resources/orphans_spiritual_nurturing.png"
import orphans_Sponsorship_Program from "../Resources/orphans_Sponsorship_Program.jpg"
import orphans_Testimonials from "../Resources/orphans_Testimonials.jpg"


class OrphnasMinistryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  
  orphans_ministry_cards = (quoteTitle,quoteOne,quoteTwo,imgSrc) => {
    return (
<div>
      <div className="row align-items-stretch">


      <div className="col-lg-3 col-md-6 mb-4">
            <div className="card">
              <div className="card-body">
              <div>
              <img 
          src={imgSrc} alt="Kingdom Aflame" 
          className="card-img-top" 
          style={{ width: '100%', maxHeight: '300px' }}
           />
                </div>
              </div>
            </div>
          </div>



      <div className="col-lg-9 col-md-6 mb-4">
      <div className="card" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{quoteTitle}</h5>
          <p className="card-text">
              {quoteOne}
          </p>
          <p className="card-text">
              {quoteTwo}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>

    );
  }




  render() {
    return (
      <div>
<div className="OrphnasMinistryPage_Container">
<div className='container'>



{this.orphans_ministry_cards(
"Introduction:",
"Welcome to the [Church Name] Orphans Ministry! Our ministry is dedicated to extending love, care, and support to children who have lost their parents, ensuring they have a safe and nurturing environment to thrive in.",
"",
orphans_introduction
)}


{this.orphans_ministry_cards(
"About the Ministry:",
  "The [Church Name] Orphans Ministry is committed to providing a loving and stable home for children who have experienced the loss of their parents. Through our ministry, we seek to fulfill the biblical mandate to care for the fatherless and offer them hope for a brighter future.",
  "",
  orphans_about
)}


{this.orphans_ministry_cards(
"Mission and Vision:",
  " Mission: Our mission is to create a loving family environment where orphans can experience God's love, receive quality education, and develop into confident and responsible individuals.",
  "  Vision: We envision a generation of children who, despite their challenges, grow up with a strong sense of belonging, purpose, and the ability to positively impact their communities.",
  orphans_mission
)}


{this.orphans_ministry_cards(
"Ministry Leaders:",
"Meet the dedicated leaders of our Orphans Ministry:",
  "•[Leader's Name]: With [years of experience] years of experience in child welfare, [Leader's Name] is passionate about providing a nurturing home for vulnerable children."
)}


{this.orphans_ministry_cards(
"Children's Homes:",
"Our ministry operates safe and loving children's homes where orphans find a supportive family environment, nutritious meals, and quality education. Each home is staffed by caring adults who act as parents and mentors.",
"",
orphans_children_homes
 )}


{this.orphans_ministry_cards(
"Education and Empowerment:",
"We believe education is key to breaking the cycle of poverty. Our ministry ensures that each child receives a quality education, equipping them with the skills and knowledge they need to succeed.",
"",
orphans_education
)}


{this.orphans_ministry_cards(
"Spiritual Nurturing:",
"We prioritize the spiritual well-being of the children by providing regular Bible studies, worship services, and opportunities for them to grow in their faith.",
"",
orphans_spiritual_nurturing
)}


{this.orphans_ministry_cards(
"Community Involvement:",
  "Our children actively participate in community outreach projects, learning the value of giving back and making a positive impact on those around them.",
  "",
  ophans_Community_Involvement
)}


{this.orphans_ministry_cards(
"Sponsorship Program:",
  "Through our sponsorship program, individuals and families can support a specific child's education, well-being, and growth. Sponsorship provides a direct connection to a child's life and progress.",
  "",
  orphans_Sponsorship_Program
)}


{this.orphans_ministry_cards(
"Testimonials:",
  "•	[Name], [Guardian of an Orphan]",
  "",
  orphans_Testimonials
)}


{this.orphans_ministry_cards(
"Get Involved:",
  "If you have a heart for orphans and would like to volunteer, contribute, or sponsor a child, please contact [Contact Name] at [Contact Email] for more information.",
  "",
  orphans_Get_involved
)}


{this.orphans_ministry_cards(
"Contact Information:",
"For inquiries, donations, or information about the Orphans Ministry, please contact [Contact Name] at [Contact Email] or [Phone Number].",
"",
orphans_contact
)}


{this.orphans_ministry_cards(
"Social Media Links:",
  "Stay connected with our Orphans Ministry and receive updates on our children's progress and ministry activities:",
  "•	Facebook Page: [Link]  •	Instagram: [Link]",
  orphans_social
)}



</div>
      </div>
      <FooterPage />
      </div>
      
    );
  }
}

export default OrphnasMinistryPage;
