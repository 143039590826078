import React, { Component } from 'react';
import FooterPage from '../AllFooter';
import widows_about from "../Resources/widows_about.jpg"
import widows_bereavement from "../Resources/widows_bereavement.jpg"
import widows_community_outreach from "../Resources/widows_community_outreach.png"
import widows_contact from "../Resources/widows_contact.svg"
import widows_get_involved from "../Resources/widows_get_involved.jpg"
import widows_introduction from "../Resources/widows_introduction.webp"
import widows_mission from "../Resources/widows_mission.png"
import widows_social from "../Resources/widows_social.jpg"
import widows_testimonials from "../Resources/widows_testimonials.jpg"
import widows_upcoming from "../Resources/widows_upcoming.jpg"



class WidowsMinistryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  widows_ministry_cards = (quoteTitle,quoteOne,quoteTwo,imgSrc) => {
    return (
<div>
      <div className="row align-items-stretch">


      <div className="col-lg-3 col-md-6 mb-4">
            <div className="card">
              <div className="card-body">
              <div>
              <img 
          src={imgSrc} alt="Kingdom Aflame" 
          className="card-img-top" 
          style={{ width: '100%', maxHeight: '300px' }}
           />
                </div>
              </div>
            </div>
          </div>



      <div className="col-lg-9 col-md-6 mb-4">
      <div className="card" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{quoteTitle}</h5>
          <p className="card-text">
              {quoteOne}
          </p>
          <p className="card-text">
              {quoteTwo}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>

    );
  }


  render() {
    return (
      <div>
 <div className="WidowsMinistryPage_Container">
<div className='container'>


{this.widows_ministry_cards(
"Introduction:",
"Welcome to the [Church Name] Widows Ministry! We are a caring and compassionate community dedicated to providing support, fellowship, and resources to widows as they navigate their journey of loss and healing.",
"",
widows_introduction
)}


{this.widows_ministry_cards(
"About the Ministry:",
"The [Church Name] Widows Ministry is a place of solace for women who have lost their spouses. We understand the unique challenges that widows face and strive to create an environment where they can find comfort, friendship, and spiritual nourishment."  ,
"",
widows_about
)}


{this.widows_ministry_cards(
"Mission and Goals:",
  "Mission: Our mission is to offer a loving community where widows can find healing, restoration, and a renewed sense of purpose as they continue their life's journey. Goals: We aim to provide emotional support, practical assistance, and spiritual guidance to widows through fellowship, counseling, and various outreach initiatives.",
  "",
  widows_mission
)}


{this.widows_ministry_cards(
"Ministry Leaders:",
  "Meet the compassionate leaders of our Widows Ministry:",
  "•	[Leader's Name]: With a heart for service and [years of experience] years of ministry, [Leader's Name] is dedicated to leading and nurturing our widows."
)}


{this.widows_ministry_cards(
"Support and Events:",
  "We offer a variety of support activities and events, including:",
  "•	Monthly Luncheons: Join us for a time of connection, sharing, and encouragement. Our luncheons are a safe space to build relationships and find support. •	Workshops and Seminars: We host workshops on topics such as grief, self-care, and financial planning to help widows navigate practical challenges.",
  widows_upcoming
)}


{this.widows_ministry_cards(
"Bereavement Counseling:",
  "We offer one-on-one bereavement counseling sessions for widows who are seeking guidance and emotional support during their grieving process.",
  "",
  widows_bereavement
)}


{this.widows_ministry_cards(
"Community Outreach:",
"Our widows are actively engaged in outreach to serve others in need, exemplifying the spirit of compassion and generosity that defines our ministry.",
"",
widows_community_outreach
)}


{this.widows_ministry_cards(
"Testimonials:",
  "•	[Name], [Widow and Ministry Participant]",
  "",
  widows_testimonials
)}


{this.widows_ministry_cards(
"Get Involved:",
"If you are a widow seeking support or if you would like to volunteer and contribute to the ministry's activities, please contact [Contact Name] at [Contact Email].",
"",
widows_get_involved
)}


{this.widows_ministry_cards(
"Contact Information:",
  "For inquiries, support, or information about the Widows Ministry, please contact [Contact Name] at [Contact Email] or [Phone Number].",
  "",
  widows_contact
)}


{this.widows_ministry_cards(
"Social Media Links:",
  "Stay connected with the Widows Ministry and receive updates on events and resources:",
  "•	Facebook Group: [Link]  •	Instagram: [Link]",
  widows_social
)}



</div>
      </div>
      <FooterPage />
      </div>
     
    );
  }
}

export default WidowsMinistryPage;
