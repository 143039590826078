import React, { Component } from 'react';
import FooterPage from '../AllFooter';
import choir_about from "../Resources/choir_about.webp"
import choir_auditions from "../Resources/choir_auditions.jpg"
import choir_introduction from "../Resources/choir_introduction.jpg"
import choir_rehearsals from "../Resources/choir_rehearsals.jpg"
import choir_Repertoire from "../Resources/choir_Repertoire.jpg"
import choir_upcoming_performances from "../Resources/choir_upcoming_performances.jpg"
import choir_impact from "../Resources/choir_impact.jpg"
import choir_get_involved from "../Resources/choir_get_involved.jpg"
import choir_contact from "../Resources/choir_contact.jpg"
import choir_social_media from "../Resources/choir_social_media.jpg"



class ChoirPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  
  choir_ministry_cards = (quoteTitle,quoteOne,quoteTwo,imgSrc) => {
    return (
<div>
      <div className="row align-items-stretch">


      
      <div className=" col-md-6 mb-4 mt-4">
            <div className="">
              {/* <div className="card-body"> */}
              <div>
              <img 
          src={imgSrc} alt="Kingdom Aflame" 
          className="women-img" 
          
           />
                {/* </div> */}
              </div>
            </div>
          </div>



          <div className="col-md-6 mb-4 mt-4">
      <div className="ministrycard" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{quoteTitle}</h5>
          <p className="card-text">
              {quoteOne}
          </p>
          <p className="card-text">
              {quoteTwo}
          </p>
          </div>
        </div>
      </div>
    </div>

    </div>
    <hr />
    </div>

    );
  }




  render() {
    return (
      <div>
        <div className="ChoirPage_Container">

      <div className='container'>


{this.choir_ministry_cards(
  "Introduction:",
  "Welcome to the [Church Name] Choir! Our choir is a vibrant and harmonious community dedicated to using our voices to uplift hearts, inspire worship, and share the message of God's love through music.",
  "",
  choir_introduction
)}


{this.choir_ministry_cards(
"About the Choir:",
"The [Church Name] Choir is composed of passionate individuals from all walks of life who share a common love for music and a desire to glorify God through song. We believe that music has the power to unite hearts and bring spiritual transformation.",
"",
choir_about
)}


{this.choir_ministry_cards(
"Director and Leadership:",
"Meet our talented choir director and leadership team:",
"•	[Director's Name]: With [years of experience] years of experience, [Director's Name] brings creativity and expertise to our choir's musical journey."
)}


{this.choir_ministry_cards(
"Choir Repertoire:",
"Our choir performs a diverse repertoire, including:",
"•	Traditional hymns that connect us to our rich history of worship. •	Contemporary worship songs that resonate with the younger generation. •	Gospel and spiritual songs that stir the soul.•	Special arrangements for holidays and events."  ,
choir_Repertoire
)}


{this.choir_ministry_cards(
"Upcoming Performances:",
"•	Easter Worship Celebration Date: [Date] Time: [Time] Location: [Church Sanctuary] Join us for a powerful Easter worship service featuring inspiring choral performances.",
"•	Christmas Cantata: Glad Tidings of Joy Date: [Date] Time: [Time] Location: [Church Auditorium] Experience the joy of the season through a musical journey celebrating the birth of Christ.",
choir_upcoming_performances
)}


{this.choir_ministry_cards(
"Rehearsals:",
"Join us for choir rehearsals every [Day] at [Time].",
"Rehearsals are held in the [Location]. We welcome new members with open hearts!",
choir_rehearsals
)}


{this.choir_ministry_cards(
"Auditions:",
"Interested in joining the choir? We welcome singers of all skill levels. Auditions are a casual and friendly process to help us get to know your voice and find the best fit within our choir.",
"",
choir_auditions
)}


{this.choir_ministry_cards(
"Community Impact:",
"Our choir doesn't just perform within the church; we also take our music beyond our walls. We engage with the community through:",
"•	Nursing home performances •	Local charity events•	Collaborations with other musical groups",
choir_impact
)}


{this.choir_ministry_cards(
"Get Involved:",
  "If you have a passion for singing and a heart for worship, we invite you to join us. Contact [Contact Name] at [Contact Email] to inquire about auditions and rehearsals.",
  "",
  choir_get_involved
)}


{this.choir_ministry_cards(
"Contact Information:",
"For more information about the choir or to inquire about performances, please contact [Contact Name] at [Contact Email] or [Phone Number].",
"",
choir_contact
)}


{this.choir_ministry_cards(
"Social Media Links:",
"Stay connected with the choir's latest news and performances:",
"•	Facebook: [Link] •	Instagram: [Link] •	YouTube: [Link]",
choir_social_media
)}



      </div>
      </div>
      <FooterPage />
      </div>
      
    );
  }
}

export default ChoirPage;
