import React, { Component } from 'react';
import logo from "./Resources/footer.png";



class FooterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  

  render() {
    return (
    


      <div className="footer_container">
        

        <section className="footer_img_container">
          <img src={logo} className="footer_img" alt="Kingdom Flame">
          </img>
        </section>

        <div className="footer_content_container">
          <div className='footer_content'>
          <p>Psalms 100</p>
          <p>Worship the LORD with gladness; come before him with joyful songs.</p> 
        </div>
        </div>
      </div>

    );
  }
}

export default FooterPage;
