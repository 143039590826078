import React, { Component } from 'react';

import women_introduction from "../Resources/women_introduction.jpg"



import HomepageSectionSix from '../sectionsix';




class WomenMinistryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }


 women_ministry_cards = (quoteTitle,quoteOne,quoteTwo,imgSrc) => {
    return (
      <div className='women'>
      <div className="row align-items-stretch">


      <div className=" col-md-6 mb-4">
            <div className="">
              {/* <div className="card-body"> */}
              <div>
              <img 
          src={imgSrc} alt="Kingdom Aflame" 
          className="women-img" 
          
           />
                {/* </div> */}
              </div>
            </div>
          </div>



      <div className="col-md-6 mb-4">
      <div className="ministrycard" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{quoteTitle}</h5>
          <p className="card-text">
              {quoteOne}
          </p>
          <p className="card-text">
              {quoteTwo}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>

    );
  }



  

  render() {
    return (
      <div className="WomenMinistryPage_Container">

       <div>
       <div className="BeliefPage_Container">
       <div className='container'>


       
       <h5 className="card-title m-4 text-center">OUR SERVICES</h5>

{/* 
{this.women_ministry_cards(
  "Introduction",
  "Welcome to the [Church Name] Women's Ministry! Our ministry is dedicated to creating a vibrant community where women of all ages can grow in their faith, build meaningful relationships, and support each other on our spiritual journeys.",
  "",
  women_introduction
)}

{this.women_ministry_cards(
"Mission and Vision:",
"Mission: Our mission is to empower women to deepen their relationship with God,connect with one another authentically, and serve our church and local community with love and compassion.",
"Vision: We envision a strong sisterhood of women who are equipped to impact the world through their faith, kindness, and dedication to living out the teachings of Jesus Christ.",
women_Mission_and_Vision
)}


{this.women_ministry_cards(
"Leadership Team:",
" Meet our passionate Women's Ministry leaders who work tirelessly to create meaningful experiences for our community:",
"•	Sarah Johnson, Ministry Coordinator, •	Emily Smith, Events Director, •	Rachel Martinez, Bible Study Coordinator"
)}


{this.women_ministry_cards(
"Upcoming Events:",
"•	Women's Retreat - Renew & Restore, Date: [Date] Time: [Time] Location: [Venue] Join us for a refreshing weekend of fellowship, worship, and renewal. Registration opens soon!",
"•	Monthly Coffee Chat Date: [Date] Time: [Time] Location: [Cafe] Enjoy a casual morning of coffee, conversation, and connection. All women are welcome!",
women_upcoming_events
)}

{this.women_ministry_cards(
"Past Events:",
"•	Annual Spring Luncheon Our recent Spring Luncheon brought together over 100 women for a time of celebration and inspiration. Guest speaker Jane Williams shared her journey of faith and resilience.",
"•	Christmas Cookie Exchange Last December, we gathered to exchange cookies and stories, spreading joy and warmth throughout our community.",
women_past
)}

{this.women_ministry_cards(
"Bible Studies:",
"Join us for our upcoming Bible study series:",
"•	Study Title: Women of Faith in the Bible, Dates: [Dates] Time: [Time] Location: [Location] Explore the lives and stories of strong women in the Bible who have shaped our faith.",
Womens_bible_study

)}

{this.women_ministry_cards(
  "Resources:",
  "Recommended Reading:",
  "Women of the Word,  by Jen Wilkin. Uninvited by Lysa TerKeurst",
  women_resources
)}


{this.women_ministry_cards(
"Testimonials:",
"[Name], [Age]",
"",
women_testimonials
)}


{this.women_ministry_cards(
  "Get Involved:",
  "We'd love for you to be part of our community! Whether you're interested in volunteering, joining a committee, or attending events, there's a place for you. Contact [Contact Name] at [Contact Email] to learn more.",
  "",
  women_get_involved
)}

{this.women_ministry_cards(
  "Contact Information:",
  "For any questions or inquiries, please feel free to reach out to our Women's Ministry team at [Email Address] or [Phone Number].",
  "",
  women_contact
)}

{this.women_ministry_cards(
  "Social Media Links:",
  "Stay connected with us on social media:",
  "•	Facebook: [Link],  •	Instagram: [Link] , •	Twitter: [Link] ",
  women_social_media
)} */}


<div className='allData' >


<div className='women'>
      <div className="row align-items-stretch">


      <div className=" col-md-6 mb-4">
            <div className="">
              {/* <div className="card-body"> */}
              <div>
              <img 
          src={women_introduction} alt="Kingdom Aflame" 
          className="women-img" 
          
           />
                {/* </div> */}
              </div>
            </div>
          </div>



      <div className="col-md-6 mb-4">
      <div className="ministrycard" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{"Leadership Team:"}</h5>
          <p className="card-text">
              {"Mission: Our mission is to empower women to deepen their relationship with God,connect with one another authentically, and serve our church and local community with love and compassion."}
          </p>
          <p className="card-text">
              {  "We'd love for you to be part of our community! Whether you're interested in volunteering, joining a committee, or attending events, there's a place for you. Contact [Contact Name] at [Contact Email] to learn more."}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>








    <div className='women'>
      <div className="row align-items-stretch">


      <div className=" col-md-6 mb-4">
            <div className="">
              {/* <div className="card-body"> */}
              <div>
              <img 
          src={women_introduction} alt="Kingdom Aflame" 
          className="women-img" 
          
           />
                {/* </div> */}
              </div>
            </div>
          </div>



      <div className="col-md-6 mb-4">
      <div className="ministrycard" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{"Leadership Team:"}</h5>
          <p className="card-text">
              {"Mission: Our mission is to empower women to deepen their relationship with God,connect with one another authentically, and serve our church and local community with love and compassion."}
          </p>
          <p className="card-text">
              {  "We'd love for you to be part of our community! Whether you're interested in volunteering, joining a committee, or attending events, there's a place for you. Contact [Contact Name] at [Contact Email] to learn more."}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>










    <div className='women'>
      <div className="row align-items-stretch">


      <div className=" col-md-6 mb-4">
            <div className="">
              {/* <div className="card-body"> */}
              <div>
              <img 
          src={women_introduction} alt="Kingdom Aflame" 
          className="women-img" 
          
           />
                {/* </div> */}
              </div>
            </div>
          </div>



      <div className="col-md-6 mb-4">
      <div className="ministrycard" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{"Leadership Team:"}</h5>
          <p className="card-text">
              {"Mission: Our mission is to empower women to deepen their relationship with God,connect with one another authentically, and serve our church and local community with love and compassion."}
          </p>
          <p className="card-text">
              {  "We'd love for you to be part of our community! Whether you're interested in volunteering, joining a committee, or attending events, there's a place for you. Contact [Contact Name] at [Contact Email] to learn more."}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>










    <div className='women'>
      <div className="row align-items-stretch">


      <div className=" col-md-6 mb-4">
            <div className="">
              {/* <div className="card-body"> */}
              <div>
              <img 
          src={women_introduction} alt="Kingdom Aflame" 
          className="women-img" 
          
           />
                {/* </div> */}
              </div>
            </div>
          </div>



      <div className="col-md-6 mb-4">
      <div className="ministrycard" style={{backgroundColor: 'grey', color:'white'}}>
        <div className="card-body">
        <div className="card-content">
        <h5 className="card-title">{"Leadership Team:"}</h5>
          <p className="card-text">
              {"Mission: Our mission is to empower women to deepen their relationship with God,connect with one another authentically, and serve our church and local community with love and compassion."}
          </p>
          <p className="card-text">
              {  "We'd love for you to be part of our community! Whether you're interested in volunteering, joining a committee, or attending events, there's a place for you. Contact [Contact Name] at [Contact Email] to learn more."}
          </p>
          </div>
        </div>
      </div>
    </div>


    </div>
    <hr />
    </div>






</div>


<hr></hr>

<h5 className="card-title m-4 text-center">WHY US ?</h5>

<hr></hr>


             </div>



             </div>
             <HomepageSectionSix />
             </div>
       







            </div>





    );
  }
}

export default WomenMinistryPage;
