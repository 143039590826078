import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navigation from './Navigation';
import Homepage from './Homepage';
import AllServicesPage from './Services/all_services';
import AboutCompany from './About_us/Company';
import Orionteam from './About_us/Team';
import GetQoutePage from './GetQoute';
import Gallerypage from './Gallery';
import ContactPage from './Contact_us';
import ContainerisedService from './Services/containerised';
import FullTruckService from './Services/full_truckLoad';
import IntermodalService from './Services/intermodal';
import TankerService from './Services/tanker_cargo';






import BlogPage from './Media_Pages/Blog';
import PhotoGalleryPage from './Media_Pages/Photo_gallery';
import PodcastPage from './Media_Pages/Podcast';
import ChoirPage from './Get_Involved_Pages/Choir';
import MembershipPage from './Get_Involved_Pages/Membership';
import OrphnasMinistryPage from './Get_Involved_Pages/Orphans_ministry';
import WidowsMinistryPage from './Get_Involved_Pages/Widows_ministry';
// import LeadersPage from './About_us/Leaders';
// import BeliefPage from './About_us/Belief';
import MultiplyFollowersPage from './Multiply_followers';
import SermonsPage from './Media_Pages/Sermons';
import BaringoRegion from './Regions/BARINGO';
import BometRegion from './Regions/BOMET';
import EmbuRegion from './Regions/EMBU';
import KeiyoRegion from './Regions/KEIYO';
import KerichoRegion from './Regions/KERICHO';
import LoitoktokRegion from './Regions/LOITOKTOK';
import MachakosRegion from './Regions/MACHAKOS';
import MakueniRegion from './Regions/MAKUENI';
import MalindiRegion from './Regions/MALINDI';
import ChurchPage from './churches/church';
import NairobiRegion from './Regions/NAIROBI';
import ChurchDetail from './Regions/singleChurchDetail ';
import NarokRegion from './Regions/NAROK';
import EldoretRegion from './Regions/ELDORET';
import MombasaRegion from './Regions/MOMBASA';
import MinistryPage from './Regions/singleMinistry';



// import AFreshStart from './Ministries_Pages/Fresh_start';
import WomenMinistryPage from './Ministries_Pages/Women';
// import YouthMinistryPage from './Ministries_Pages/Youth';
// import Locations from './Ministries_Pages/Locations';


function App() {
  return (
    <div className="App">
      

<BrowserRouter>
<Navigation /> 
<Routes>

{/* <Switch> */}


    <Route path="/" exact element={<Homepage/>} />
    <Route path="/Our_services" element={ <AllServicesPage/> } />
    <Route path="/About_Orion" exact element={<AboutCompany/>} />
    <Route path="/About_Team" exact element={<Orionteam/>} />
    <Route path="/Get_a_qoute" exact element={<GetQoutePage/>} />
    <Route path="/Gallery" exact element={<Gallerypage/>} />
    <Route path="/Contact_Us" exact element={<ContactPage/>} />
    <Route path="/Services_Containerised_cargo" exact element={<ContainerisedService/>} />
    <Route path="/Services_Full_Truck_Load" exact element={<FullTruckService/>} />
    <Route path="/Services_Intermodal" exact element={<IntermodalService/>} />
    <Route path="/Service_Tanker" exact element={<TankerService/>} />

    
    
    
    











    {/* <Route path="/Fresh_start" element={<AFreshStart />} /> */}
    <Route path="/Women_ministry" element={<WomenMinistryPage/>} />
    {/* <Route path="/Youth_ministry" element={<YouthMinistryPage/>} /> */}
    {/* <Route path="/Our_churches" element={<Locations/>} /> */}



    <Route path="/Blog" element={<BlogPage/>} />
    <Route path="/Photo_gallery" element={<PhotoGalleryPage/>} />
    <Route path="/Podcast" element={<PodcastPage/>} />
    <Route path="/Choir" element={<ChoirPage/>} />
    <Route path="/Membership" element={<MembershipPage/>} />
    <Route path="/Orphans_ministry" element={<OrphnasMinistryPage/>} />
    <Route path="/Widows_ministry" element={<WidowsMinistryPage/>} />
    {/* <Route path="/Leadership" element={<LeadersPage/>} /> */}
    {/* <Route path="/Our_belief" element={<BeliefPage/>} /> */}
    <Route path="/Multiply_Campaign" element={<MultiplyFollowersPage/>} />
    <Route path="/Sermons" element={<SermonsPage/>} />

    

    
    <Route path="/Embu_region" element={<EmbuRegion/>} />

    <Route path="/Nairobi_Region" element={<NairobiRegion/>} />
    <Route path="/Machakos_region" element={<MachakosRegion/>} />
    <Route path="/Makueni_region" element={<MakueniRegion/>} />
    <Route path="/Kericho_region" element={<KerichoRegion/>} />
    <Route path="/Bomet_region" element={<BometRegion/>} />
    <Route path="/Baringo_region" element={<BaringoRegion/>} />
    <Route path="/Keiyo_region" element={<KeiyoRegion/>} />
    <Route path="/Malindi_region" element={<MalindiRegion/>} />
    <Route path="/Loitoktok_region" element={<LoitoktokRegion/>} />
    <Route path="/Narok_region" element={<NarokRegion/>} />
    <Route path="/Eldoret_region" element={<EldoretRegion/>} />
    <Route path="/Mombasa_region" element={<MombasaRegion/>} />


    <Route path="/Church" element={<ChurchPage/>} />
    <Route path="/church/:churchName" element={<ChurchDetail />} />
    <Route path="/church/:churchName/:ministryName" element={<MinistryPage />} />
    <Route path="/church/:churchName/:churchName/:ministryName" element={<MinistryPage />} />





{/* </Switch> */}

    </Routes>
</BrowserRouter>

      
    </div>
  );
}

export default App;
