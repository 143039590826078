import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import truck_highway from "./Resources/truckhighway.png";
// import logo from "./Resources/orion logo round.png";


import orion_front from "./Resources/orion front.jpeg";
import comes_second from "./Resources/comes second.jpeg";
import tankers_third from "./Resources/tankers third.jpeg";
import top_view_fourth from "./Resources/top view fourth.jpeg";

class HomepageSectionTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
    this.intervalId = null;
  }

 

  showNextImage = () => {
    const { currentIndex } = this.state;
    const album = document.querySelector('.album');
    if (album) {
      const nextIndex = (currentIndex + 1) % 4; // Assuming there are 4 images
      const translateValue = `translateX(${-nextIndex * 100}%)`;
  
      // Reset transition just before making the transition
      album.style.transition = 'none';
      album.style.transform = translateValue;
  
      // Triggering a reflow to apply the style immediately without a transition
      void album.offsetWidth;
  
      // Apply the transition for the actual transition
      album.style.transition = 'transform 0.5s ease-in-out';
  
      this.setState({ currentIndex: nextIndex });
    }
  }
  


  componentDidMount() {
    this.intervalId = setInterval(this.showNextImage, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    return (
      <div className="home-container" style={{ marginTop: 20 + 'px' }}>

        <div className="weareglad row text-center">

          <div className="welcome col-md-5 text-center ">
            <h2>About Us</h2>

            <h5>Early Years</h5>

            <p className='text-dark'>
              The company's roots can be traced back to a small garage in Cabanas (Nairobi),
              where the initial fleet of three trucks embarked on local deliveries. The founding team
               infused the business with a passion for excellence and a commitment
              to customer satisfaction.
            </p>

            <h5>Expansion and Growth</h5>

            <p className='text-dark'>
              Driven by a dedication to service quality, Orion Hauliers Ltd rapidly expanded its operations.
              The company's reputation for punctuality and professionalism attracted a growing clientele,
              leading to increased demand for its services. Over the years,
              the fleet has grown from its humble beginnings to an impressive convoy of 20 trucks,
              covering a wider geographical reach.
            </p>

            <h5>Looking Ahead</h5>

            <p className='text-dark'>
              Orion Hauliers Ltd remains committed to its founding principles of
              reliability, innovation, and customer satisfaction.
              With an eye on the future, the company is exploring sustainable practices
              and further technological enhancements to continue its trajectory of growth and success.
            </p>

            <div className="hero-buttons">
              <button type='button' className="btn btn-info">
              <Link to="/About_Orion" className="dropdown-item">Read more!
              <i className="fa-solid fa-arrow-right"></i>
              </Link>
              </button>
            </div>
          </div>


          <div className="welcomeimg col-md-5">
            <div className="album-container">
              <div className="album">
                <div>
                  <img src={orion_front} alt="ORION HAULIERS LTD" />
                  <div className="caption">Custom wording 1</div>
                </div>
                <div>
                  <img src={comes_second} alt="ORION HAULIERS LTD" />
                  <div className="caption">Custom wording 2</div>
                </div>
                <div>
                  <img src={tankers_third} alt="ORION HAULIERS LTD" />
                  <div className="caption">Custom wording 3</div>
                </div>
                <div>
                  <img src={top_view_fourth} alt="ORION HAULIERS LTD" />
                  <div className="caption">Custom wording 4</div>
                </div>
              </div>
            </div>
          </div>



        </div>

        <div className="banner">
          <div className="left-part">
            <div className="banner_text">
              <h2>ARE YOU LOOKING FOR</h2>
              <h2>RELIABLE TRANSPORT SERVICES?</h2>
            </div>
          </div>
          <div className="right-part">
            <div className="banner_text">
              <h2>GIVE US A CALL</h2>
              <h2>+254 752 554 470 </h2>
              <h2>+254 715 554 470</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomepageSectionTwo;
