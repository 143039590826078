import React, { Component } from 'react';
import FooterPage from '../AllFooter';
import logo from "../Resources/logo.jpg";
import { Link } from 'react-router-dom';
// import HomepageSectionSix from './sectionsix';



class ChurchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }




  

  render() {
    return (
      <div>
 <div className="LeadersPage_Container">

<div className="container-fluid">

     
{/* calling the leaders card and providing the appropriate parameters */}
<h3 className='text-center mt-5'>MANYATTA CHURCH (EMBU) </h3>
<div className="row m-3 p-4">
  <div className="col-md-5">
    <div className=" p-2 m-1 ">
      <img className='bishopimg text-justify' src="https://img.freepik.com/free-vector/hand-drawn-flat-design-pastor-illustration_23-2149336956.jpg" alt="" />
      <h4>Pastor ABC</h4>
      <p className='text-dark'>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ducimus. 
      </p>
    </div>
  </div>
  <div className="col-md-7 ourchurches">
    <h4>Our Ministries</h4>
    <div className='churches row'>
      <div className="col-md-3">
      <Link to="/women_ministry" className='link'>
      Women
      </Link>
      </div>
    <div className="col-md-3">
    <li>Youths</li>
    </div>
    <div className="col-md-3">
    <li>Junior Youths</li>
    </div>
    <div className="col-md-3">
    <li>Children</li>

    </div>
  
    </div>
    <div className="services row">
  <div className="col-md-2"></div>
  <div className="servicestext mt-5 m-5 col-md-4 mt-6">
  <h3 class="m-2 p-1">SUNDAY WORSHIP SCHEDULES</h3>
  <p>6.00 AM  -  8.30 AM  - YOUTH SERVICE</p>
              <p>9.00 AM  -  10.30 AM  -  KISWAHILI SERVICE</p>
              <p>11.00 AM  -  1.30 PM  -  ENGLISH SERVICE</p>
              <p>2.00 PM  -  3.30 PM  -  GROUP MEETINGS</p>
              <p>4.00 PM  -  6.30 PM  -  CHOIR PRACTISE</p>

  </div>
  <div className="">
  <button className="give">Give <i class="fa-solid fa-hand-holding-hand"></i></button>

  </div>
</div>

  </div>
</div>


</div>
      </div>
      <div className="footer">
      <div className="home-container footer ">

<div className='sectionsix row'>
<div className="col-md-4 socials text-center">
  <h4>Our Socials</h4>
        <p className='text-center text-justify'><a href='\Facebook_events' >Facebook</a><br></br>
        <a href='\Twitter_events' >Twitter</a><br></br>
        <a href='\Instagram_events' >Instagram</a>
        </p>
  </div>
  <div className="col-md-4">
      <h4>About Kingdom Aflame</h4>
      <p>"Kingdom Aflame Church in Kapsoya, Kenya, is a vibrant and inclusive spiritual community committed to igniting faith, hope, and love. Through worship, outreach, and teaching, the church aims to empower individuals and bring positive change to Kapsoya and beyond."</p>
  </div>
  
       <div className="col-md-4 connect">
        <h4>Connect</h4>
        
        <p className='text-center text-justify'><a href='\Facebook_events' >Weekends</a><br></br>
        <a href='\Twitter_events' >Get Involved</a><br></br>
        <a href='\Instagram_events' >Contact Us</a><br/>
        <a href='\Instagram_events' >Give</a>

        </p>
       </div>
       
</div>
      </div>
      </div>
      {/* <HomepageSectionSix /> */}

      </div>
    );
  }
}

export default ChurchPage;

